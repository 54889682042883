.attendence-submission {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}

.textarea {
    height: 60%;
    width: 40%;
}

@media (max-width: 785px) {
    .attendence-submission {
        flex-direction: column;
        margin-top: 10px;
    }
    .att-con {
        display: flex;
        justify-content: space-between;
        width: 80%;
    }
    .textarea {
        width: 90%;
        margin-bottom: 10px 
    }
}