.user-card-content{
    display: flex;
}

.study-history{
    width: 58%;
}


@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2) {

    .user-card-content{
        flex-direction: column;
    }


    .study-history{
        width: 100%;
    }

}